export const ourCollectionsCardData = [
  {
    collectionName: 'Phantom Zero',
    artist: 'Michael Kutsche',
    description:
      <>
        A mind-blowing and degenerate universe of colors and
        crypto-art by Michael Kutsche, a successful award-winning
        artist known for his work as the artist behind Disney’s The
        Lion King, Dumbo, The Jungle Book and Tim Burton’s Alice
        in Wonderland.
      </>,
    link: 'https://twitter.com/PhantomZeroNFT',
    collectionImage: '/assets/collections/collection-1.jpg',
    artistImage: '/assets/collections/artist-1.png'
  },

  {
    collectionName: 'Blade 7',
    artist: 'Pascal Blanché',
    description:
      <>
        A beautiful and original universe of toy-like samurais by
        world-renowned artist Pascal Blanché known for his work in
        the video-game industry on Assassin’s Creed, RuneScape or
        even Far Cry: New Dawn.
      </>,
    link: 'https://twitter.com/Blade7NFT',
    collectionImage: '/assets/collections/collection-2.jpg',
    artistImage: '/assets/collections/artist-2.png'
  }
]
