import React from 'react'
import { WorkCollectionNameStyle } from './WorkCollectionName.style'

const WorkCollectionName = ({
  company, collection, className
                            }) => {
  return (
    <WorkCollectionNameStyle
      className={['work-collection-name', className].join(' ')}
    >
      <span className="company text-shadow">{company}</span>

      <span className="dots">
        {new Array(200).fill('.').join(' ')}
      </span>

      <span className="collection text-shadow">{collection}</span>
    </WorkCollectionNameStyle>
  )
}

export default WorkCollectionName
