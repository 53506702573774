import React from 'react'
import { PropellersItemStyle } from './PropellersItem.style'

const PropellersItem = ({img, title, text, className}) => {
  return (
    <PropellersItemStyle className={['propellers-item', className].join(' ')}>
      <img src={img} alt={title} />

      <h3>{title}</h3>

      <p>{text}</p>
    </PropellersItemStyle>
  )
}

export default PropellersItem
