import styled from 'styled-components'

export const SectionHeroStyle = styled.main`
  padding-top: 7.7vw;
  //overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-top: 10.937vw;
  }
`

export const HeroWrapper = styled.div`
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    flex-direction: column-reverse;
  }
`

export const HeroTextContainer = styled.div`
  max-width: 81.25vw;
  margin: 0 auto;
`

export const HeroTextContent = styled.div`
  max-width: 37.4vw;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 20;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    position: relative;
    transform: unset;
    top: unset;
    max-width: 78vw;
    text-align: center;
  }

  > h1 {
    font-size: 3.622vw;
    line-height: 3.463vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 6.807vw;
      line-height: 6.509vw;
    }

    > span {
      color: ${({ theme }) => theme.colors.primaryLight};
    }
  }

  > p {
    margin-top: 1.5vw;
    font-size: 1.519vw;
    line-height: 2.17vw;
    letter-spacing: 0.08em;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 3.798vw;
      line-height: 5.425vw;
    }
  }

  > .btn-discover {
    display: inline-block;
    margin-top: 4.1666vw;
    font-family: ${({ theme }) => theme.font.primary};
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.secondary};
    padding: 1.041vw;
    font-size: 1vw;
    cursor: pointer;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 2.5vw;
      padding: 3vw;
    }
  }
`

export const HeroImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  position: relative;
  z-index: 3;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      z-index: -3;
  }

  .desktop, .mobile {
    position: relative;
  }

  .desktop {
    position: relative;
    width: 50vw;
    right: 2vw;


    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      display: none;
    }
  }

  .mobile {
    position: relative;
    display: none;
    width: 72vw;
    margin: 0 auto;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      display: block;
      margin-bottom: -32vw;
    }
  }
`

export const HeroBanner = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  width: 100%;
  position: relative;
  margin-top: -4%;
  z-index: 2;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 7vw;
  }

  > div {
    width: fit-content;
    margin: 0 auto;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    > h3 {
      font-family: ${({ theme }) => theme.font.primary};
      font-size: 1.735vw;
      line-height: 3.463vw;
      text-align: center;

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 2.416vw;
        line-height: 3.645vw;
      }
    }

    > h4 {
      font-size: 1.458vw;
      line-height: 2.91vw;
      font-family: ${({ theme }) => theme.font.secondary};
      text-align: right;

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 2.286vw;
        line-height: 4.665vw;
        text-align: center;
      }
    }
  }


  > img {
    position: relative;
    z-index: 1;
    opacity: 0.95;
  }

  .desktop {
    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      display: none;
    }
  }

  .mobile {
    display: none;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      display: block;
    }
  }
`
