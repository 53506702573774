import styled from 'styled-components'

export const SectionDiscoverStyle = styled.section`
  padding-top: 13.5vw;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      padding-top: 21vw;
  }
`

export const DiscoverContainer = styled.div`
  max-width: 71.614vw;
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 92.317vw;
  }
`

export const DiscoverContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > h2 {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 3.472vw;
    line-height: 3.689vw;
    text-align: center;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 6.665vw;
      line-height: 7.082vw;
    }

    > span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  > iframe {
    margin-top: 3.333vw;
    width: 100%;
    aspect-ratio: 560 / 315;
  }
`
