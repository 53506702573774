import React from 'react'
import { CarouselStyle } from './Carousel.style'
import Slider from 'react-slick'

const settings = {
  dots: true,
  infinite: true,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false,
  speed: 1000,
  autoplaySpeed: 4000,
  autoplay: true
}

export const Carousel = ({ imagesList, className }) => {
  return (
    <CarouselStyle className={['carousel', className].join(' ')}>
      <Slider {...settings}>
        {imagesList.map((image, idx) => (
          <div key={`${image.alt}_${idx}`} className="image-container">
            <picture>
              {image.src?.webp
                ? (
                  <>
                    <source type="image/webp" srcSet={image.src.webp}/>
                    <img src={image.src.default} alt={image.alt}/>
                  </>
                ) :
                <img src={image.src} alt={image.alt}/>
              }
            </picture>
          </div>
        ))}
      </Slider>
    </CarouselStyle>
  )
}
