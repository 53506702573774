import styled from 'styled-components'

export const PioneerCardStyle = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
  background-image: linear-gradient(-76deg, rgba(93, 43, 207, 1) 0%, rgba(35, 174, 226, 1) 100%);;

  ::after, ::before {
    content: '';
    position: absolute;
    z-index: 5;
    inset: 0;
  }

  ::after {
    z-index: 5;
    background-image: linear-gradient(326deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }

  ::before {
    z-index: 3;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    min-height: 80vw;

  }

  > div {
    position: relative;
    z-index: 10;
    padding: 0 2.291vw 4.583vw;
    height: 100%;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      padding-left: 5.729vw;
      padding-right: 5.729vw;
      padding-bottom: 8.333vw;
    }

    > img {
      max-width: 8.072vw;
      position: absolute;
    }

    > h3 {
      font-family: ${({ theme }) => theme.font.primary};
      font-size: 1.953vw;
      line-height: 2.17vw;
      color: ${({ theme }) => theme.colors.primary};

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 4.4vw;
        line-height: 5.425vw;
      }
    }

    > ul {
      list-style-type: none;
      display: grid;
      row-gap: 1.666vw;
      margin-top: 2.17vw;

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
          margin-top: 5.208vw;
      }

      > li {
        font-size: 1.519vw;
        line-height: 2.17vw;
        position: relative;
        padding-left: 1.5vw;

        @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
          font-size: 3.798vw;
          line-height: 5.425vw;
        }

        ::before {
          content: '-';
          margin: 0 0.52vw;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }


`
