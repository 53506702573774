import React from 'react'
import {
  CollectionGroup,
  OurCollectionContent, OurCollectionTitle,
  SectionOurCollectionStyle
} from './SectionOurCollection.style'
import { forwardRef } from 'react'
import { OurCollectionCard } from './OurCollectionCard/OurCollectionCard'
import { ourCollectionsCardData } from './our-collections-data'

const SectionOurCollection = forwardRef((props, ref) => {
  return (
    <SectionOurCollectionStyle ref={ref} id="our-collection">
      <OurCollectionContent>
        <OurCollectionTitle>
          <div />

          <h2 className="text-shadow">
            OUR <br /><span>COLLECTIONS</span>
          </h2>
        </OurCollectionTitle>

        <CollectionGroup>
          {ourCollectionsCardData.map((data, idx) => (
            <OurCollectionCard
              key={`our-collection-card-${idx}`}
              className={`our-collection-card-${idx + 1}`}
              reverse={idx % 2 !== 0}
              {...data}
            />
          ))}
        </CollectionGroup>
      </OurCollectionContent>
    </SectionOurCollectionStyle>
  )
})

export default SectionOurCollection
