import styled from 'styled-components'

export const WorkArtistCardStyle = styled.div`
  width: 100%;
  //background-color: green;

  > .mobile-title {
    display: none;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    >h3 {
      font-size: 4.95vw;
      font-family: ${({ theme }) => theme.font.primary};
      color: ${({ theme }) => theme.colors.secondary};
      line-height: 5.2vw;
      width: 100%;
      //background-color: red;
      text-align: center;

      > span {
        color: ${({ theme }) => theme.colors.lightGreen};
      }
    }

    >.triangle {
      //background-color: red;

      &-right, &-left {
        position: relative;

        ::after {
          content: '';
          position: absolute;
          inset: 0;
          z-index: 3;
        }
      }

      &-left {
        ::after {
          background-image: linear-gradient(90deg, rgba(22,50,60,0) 80%, rgba(0,0,0,.5) 100%);
        }
      }

      &-right {
        ::after {
          background-image: linear-gradient(270deg, rgba(22,50,60,0) 80%, rgba(0,0,0,.5) 100%);
        }
      }
    }
  }
`

export const WorkArtistCardContent = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      flex-direction: column;
  }
`

export const WorkArtistCardImage = styled.div`
  flex-shrink: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: 5.2vw;
  }

  > img {
   max-width: 21.4vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        max-width: 52vw;
    }
  }

`

export const WorkArtistCardInfo = styled.div`
  margin-left: 6.25vw;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-left: 0;
  }

  > h3 {
    font-size: 2.135vw;
    font-family: ${({ theme }) => theme.font.primary};
    color: ${({ theme }) => theme.colors.secondary};
    line-height: 2.91vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      display: none;
    }

    > span {
      color: ${({ theme }) => theme.colors.lightGreen};
      font-size: 2.76vw;
    }
  }

  > p {
    margin-top: 2.5vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-top: 8vw;
    }
  }
`
