import styled from 'styled-components'

export const SectionTeamStyle = styled.section`
  padding-top: 11.5vw;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-top: 10.833vw;
    margin-top: 10vw;
  }
`

export const TeamContainer = styled.div``

export const TeamContent = styled.div``

export const TeamTitle = styled.div`
  display: flex;
  align-items: center;
  max-width: 81.25vw;
  margin: 0 auto;

  > h2 {
    font-size: 3.436vw;
    line-height: 3.645vw;
    font-family: ${({ theme }) => theme.font.primary};
    color: ${({ theme }) => theme.colors.primary};
    text-align: center;
    margin-left: 5vw;
    margin-right: 5vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 8.68vw;
      line-height: 9.222vw;
    }

    > span {
      color: ${({ theme }) => theme.colors.secondary};
      font-size: 2.6vw;

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 6.51vw;
      }
    }
  }

  >div {
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.primary};
  }
`

export const TeamMembersContainer = styled.div`
  max-width: 61.458vw;
  margin: 3.4375vw auto 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 11.718vw;
  }


  > .row {
    &-1, &-2, &-3 {
      display: grid;
      justify-content: center;
      column-gap: 3.125vw;
    }

    &-1, &-2, &-3 {
      grid-template-columns: repeat(3, 19.1vw);
    }

    &-2, &-3 {
      margin-top: 4.583vw;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      &-1, &-2, &-3 {
        grid-template-columns:47.53vw;
        row-gap: 13vw;
      }

      &-2, &-3 {
        margin-top: 13vw;
      }
    }
  }
`
