import styled from 'styled-components'

export const PropellersItemStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;

  > img {
    height: 9vw;
    width: auto;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      height: 18.88vw;
    }
  }

  > h3 {
    font-family: ${({ theme }) => theme.font.primary};
    color: ${({ theme }) => theme.colors.primary};
    margin-top: 1vw;
    font-size: 1.953vw;
    line-height: 2.066vw;
    text-transform: uppercase;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 4.861vw;
      line-height: 4.63vw;
      margin-top: 2vw;
    }
  }

  > p {
    font-size: 1.519vw;
    line-height: 2.17vw;
    margin-top: 1.25vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 3.403vw;
      line-height: 4.861vw;
      margin-top: 3vw;
    }
  }
`
