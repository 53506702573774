export const pioneersData = [
  {
    img: '/assets/pioneers/technical-expertise.png',
    title: 'Technical Expertise',
    list: [
      'Programing the most optimized smart contract to deliver a top-quality minting experience',
      'Rendering and generating your collection with an in-depth analysis of the rarity structure',
      'Designing and developing the best website for your collection',
      'Ongoing research and development to stay up-to-date and innovate in the market'
    ]
  },

  {
    img: '/assets/pioneers/community-management.png',
    title: 'Community Management',
    list: [
      'Devise the best strategies to grow an organic, loyal and dedicated community',
      'Managing day-to-day communication on social media to ensure community updates and operational transparency',
      'Creating state-of-the-art Discover servers and Twitter editorial plans'
    ]
  },

  {
    img: '/assets/pioneers/growth.png',
    title: 'Growth',
    list: [
      'Ongoing analysis of the market in search of the latest trends and innovative strategies',
      'Leveraging our wide network of quality connections',
      'Devising top-notch strategies to ensure steady and quality market-awareness of the project'
    ]
  }
]
