import styled from 'styled-components'

export const SectionWorkStyle = styled.section`
  padding-top: 7.3vw;
`

export const SectionWorkTitle = styled.div`
  display: flex;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: center;
  }

  > h2 {
    font-size: 3.645vw;
    line-height: 3.472vw;
    margin-right: 3.125vw;
    flex-shrink: 0;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 7.28vw;
      line-height: 6.9vw;
      text-align: center;
      margin-right: 0;
    }

    > span {
      &.small {
        font-size: 1.95vw;

        @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
          font-size: 3.9vw;
        }
      }
    }
  }

  > div {
    align-self: flex-end;
    margin-bottom: 1.5vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      display: none;
    }
  }
`

export const SectionWorkContent = styled.div`
  margin-top: 6.25vw;
`

export const DiscoverTitle = styled.h3`
  color: ${({ theme }) => theme.colors.lightGreen};
  font-size: 1.65vw;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.font.primary};

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 4.15vw;
    text-align: center;
  }
`

export const SectionMichael = styled.div`
  > .work-artist-card {
    margin-bottom: 4.65vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-bottom: 8vw;
    }
  }

  .discover-collections {
    margin-top: 1.6vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: 5vw;
    }

    > *:not(:first-child) {
      margin-top: 0.7vw;

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-top: 2vw;
      }
    }
  }

  .collections {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    width: 100%;
    margin-top: 5.2vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      flex-direction: column;
    }

    > * {
      :nth-child(2) {

        @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
          margin-top: 16vw;
        }
      }

      > .img-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        //background-color: red;
        height: 6.45vw;
        margin-bottom: 0.7vw;

        @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
          height: 14.5vw;
          margin-bottom: 1vw;
        }

        > img {
          height: auto;
        }

        > .super-rare {
          max-width: 20.2vw;

          @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
            max-width: 45.3vw;
          }
        }

        > .disney {
          max-width: 15vw;

          @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
            max-width: 33.3vw;
          }
        }
      }

      width: calc(50% - 1.25vw);

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        width: 100%;
      }
    }
  }
`

export const SectionPascal = styled.div`
  margin-top: 12vw;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 22vw;
  }

  > .work-artist-card {
    margin-bottom: 4.65vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-bottom: 8vw;
    }
  }

  > .discover-collections {
    margin-top: 1.6vw;

    > *:not(:first-child) {
      margin-top: 0.7vw;
    }
  }

  .discover-collections {
    margin-top: 1.6vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: 5vw;
    }

    > *:not(:first-child) {
      margin-top: 0.7vw;

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-top: 2vw;
      }
    }
  }

  .collections {
    display: flex;
    justify-content: center;

    max-width: 100%;
    width: 100%;
    margin-top: 6.25vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: 7vw;
    }

    > * {
      width: calc(50% - 1.25vw);

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        width: 100%;
      }
    }
  }
`


