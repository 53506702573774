import React from 'react'
import { PioneerCardStyle } from './PioneerCard.style'

export const PioneerCard = ({ className, img, title, list }) => {
  return (
    <PioneerCardStyle className={['pioneer-card', className].join(' ')}>
      <div>
        <img src={img} alt={title} />

        <h3>{title}</h3>

        <ul>
          {list.map((item, idx) => (
            <li key={`${title}_${idx}`}>{item}</li>
          ))}
        </ul>
      </div>

    </PioneerCardStyle>
  )
}
