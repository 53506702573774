import React from 'react'
import { TeamMemberStyle } from './TeamMember.style'

const TeamMember = ({
                      img,
                      name,
                      position,
                      description,
                      className = '',
                      link
                    }) => {
  return (
    <TeamMemberStyle className={['team-member', className].join(' ')}>
      {link ?
        <a href={link} target="_blank" rel="noreferrer">
          <img src={img} alt={name} />
        </a>
        : <img src={img} alt={name} />
      }

      <h3>{name}</h3>

      <span>{position}</span>

      {description && <p>{description}</p>}
    </TeamMemberStyle>
  )
}

export default TeamMember
