import styled, { css } from 'styled-components'

export const Container = styled.div`
  max-width: 81.25vw;
  margin: 0 auto;
`

export const ContainerMd = styled.div`
  max-width: 73.3vw;
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      max-width: 82vw;
  }
`

export const Line = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.primary};
  width: 100%;
`

export const Triangle = styled.div`
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  position: relative;
  z-index: 1;


  ${({ right }) => right && css`
    border-color: transparent ${({ color }) => color} transparent transparent;
    border-width: ${({ height }) => height / 2}vw ${({ width }) => width}vw ${({ height }) => height / 2}vw 0;
  `}

  ${({ left }) => left && css`
    border-color: transparent transparent transparent ${({ color }) => color};
    border-width: ${({ height }) => height / 2}vw 0 ${({ height }) => height / 2}vw ${({ width }) => width}vw;
  `}
`
