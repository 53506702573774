export const propellersData = [
  {
    img: '/assets/propellers/knowledge.png',
    title: 'Knowledge',
    text: <>
      We are a team of experienced
      Web3 entrepreneurs with a
      significant track record.
    </>
  },

  {
    img: '/assets/propellers/structure.png',
    title: 'Structure',
    text: <>
      We provide artists with the
      best possible tools, teams and
      strategies to bring their ideas
      to life.
    </>
  },

  {
    img: '/assets/propellers/ambition.png',
    title: 'Ambition',
    text: <>
      We are dedicated to making
      any project we work on a
      success.
    </>
  },

  {
    img: '/assets/propellers/fairness.png',
    title: 'Fairness',
    text: <>
      We reward artists fairly, and
      never forget that it is their
      project before ours.
    </>
  },

  {
    img: '/assets/propellers/network.png',
    title: 'Network',
    text: <>
      We leverage our network in
      order to build and grow
      steadily.
    </>
  }
]
