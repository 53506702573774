export const teamData = [
  {
    img: '/assets/team/team-1.png',
    name: 'T0PL - Theo',
    position: 'Founder, CEO',
    description: <>
      "There is only one thing that is <br />
      worse than being talked <br />
      about, and that is not being <br />
      talked about." - Oscar Wilde
    </>
  },
  {
    img: '/assets/team/team-2.png',
    name: 'Maxenzz - Maxence',
    position: 'COO, Head of Strategy',
    description: <>
      "A goal without a plan is <br />
      called a wish" - Antoine <br />
      de Saint-Exupéry
    </>
  },
  {
    img: '/assets/team/team-3.png',
    name: 'Orbore - Jérôme',
    position: 'Discord Wizard',
    description: <>
      "More animated GIFs please."
    </>
  },
  {
    img: '/assets/team/team-4.png',
    name: 'PI2INCE - Rhenz',
    position: 'Collabs Manager',
    description: <>
      "The way to get started <br />
      is to quit talking and <br />
      begin doing." - Walt Disney
    </>
  },
  {
    img: '/assets/team/team-5.png',
    name: 'XV1V - Vive',
    position: 'Community Manager',
    description: <>
      "A little kindness goes a long <br/>
      way"
    </>
  },
  {
    img: '/assets/team/team-9.png',
    name: 'XB3N - Ben',
    position: 'Community Manager',
    description: <>
      ""Everything in moderation. Including moderation"
    </>
  },

  {
    img: '/assets/team/team-6.png',
    name: 'Killua - Younes',
    position: 'Graphic Designer',
    description: <>
      "Make the logo bigger"
    </>
  },

  {
    img: '/assets/team/team-7.png',
    name: 'Array - Alain',
    position: 'Developer',
    description: <>
      "First, solve the problem. Then write the code." - John Johnson
    </>
  },

  {
    link: 'https://nftstack.info/',
    img: '/assets/team/team-8.png',
    name: 'NFT Stack',
    position: 'Software Development',
  }
]
