import styled from 'styled-components'

export const FooterStyle = styled.footer`
  margin-top: 12vw;
  margin-bottom: 16vw;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 31.25vw;
    margin-bottom: 18.229vw;
  }
`

export const FooterContainer = styled.div`
  max-width: 39.583vw;
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 77.2vw;
  }
`

export const FooterContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  > h2 {
    font-size: 2.865vw;
    line-height: 3.045vw;
    text-align: center;

    >span {
      color: ${({ theme }) => theme.colors.primary};
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 5.710vw;
      line-height: 6.067vw;
    }
  }

  > a {
    display: inline-block;
    height: 6.979vw;
    width: 6.979vw;
    margin-top: 2.916vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 14.062vw;
      height: 14.062vw;
      margin-top: 5.208vw;
    }
  }
`
