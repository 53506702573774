export const theme = {
  colors: {
    primaryLight: '#57b5b8',
    primary: '#4fa7b2',
    secondary: '#ffffff',
    lightGreen: '#9fcfb1',
    lightBlue: '#69afb0',
  },
  gradients: {
  },
  shadows: {
  },
  font: {
    primary: "'Mantra Style', sans-serif",
    secondary: "'Euro Style', sans-serif",
    tertiary: "'Myriad Pro', sans-serif",
  },

  breakpoints: {
    xs: "359px",
    sm: "767px",
    md: "991px",
    lg: "1199px",
    xl: "1439px",
    xxl: "1919px"
  },
}
