import styled from 'styled-components'

export const SectionOurCollectionStyle = styled.section`
  padding-top: 10.5vw;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 10vw;
    padding-top: 10.416vw;
  }
`

export const OurCollectionContent = styled.div`

`

export const OurCollectionTitle = styled.div`
  max-width: 81.25vw;
  margin: 0 auto;
  display: flex;

  > div {
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.primary};
    align-self: end;
    margin-right: 3.333vw;
    margin-bottom: 1.458vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        display: none;
    }
  }

  > h2 {
    text-align: right;
    width: fit-content;
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 3.472vw;
    line-height: 3.689vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 6.665vw;
      line-height: 7.082vw;
      text-align: center;
      max-width: 78vw;
      width: 100%;
    }

    > span {
      color: ${({ theme }) => theme.colors.primary}
    }
  }

`

export const CollectionGroup = styled.div`
  margin-top: 8.5vw;
  display: flex;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 13vw;
  }

  >.our-collection-card {
    &-1 {
      .artist {
        top: -2vw;

        @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
            top: -4vw;
        }
      }
    }

    &-2 {
      margin-top: 8vw;

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
          margin-top: 14.5vw;
      }

      .artist {
        top: -4vw;
      }
    }
  }
`

