import React from 'react'
import {
  WorkArtistCardContent, WorkArtistCardImage, WorkArtistCardInfo,
  WorkArtistCardStyle
} from './WorkArtistCard.style'
import { ContainerMd, Triangle } from '../../../styles/components.style'
import { theme } from '../../../styles/theme'

const WorkArtistCard = ({
                          name,
                          description,
                          image,
                          className
                        }) => {
  return (
    <WorkArtistCardStyle className={['work-artist-card', className].join(' ')}>
      <div className="mobile-title">
        <div className="triangle-left">
          <Triangle left width={24} height={.6}
                    color={theme.colors.lightGreen} />
        </div>

        <h3 className="text-shadow ">
          {name.split(' ')[0]} <br />
          <span>
          {name.split(' ')[1]}
        </span>
        </h3>

        <div className="triangle-right">
          <Triangle right width={24} height={.6}
                    color={theme.colors.lightGreen} />
        </div>
      </div>


      <ContainerMd>
        <WorkArtistCardContent>
          <WorkArtistCardImage>
            <img src={image} alt={name} />
          </WorkArtistCardImage>

          <WorkArtistCardInfo>
            <h3 className="text-shadow">
              {name.split(' ')[0]} <br />
              <span>
                {name.split(' ')[1]}
              </span>
            </h3>

            <p className="font-base">
              {description}
            </p>
          </WorkArtistCardInfo>
        </WorkArtistCardContent>
      </ContainerMd>
    </WorkArtistCardStyle>
  )
}

export default WorkArtistCard
