import { createGlobalStyle, keyframes } from 'styled-components'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1
  }
`

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Mantra Style';
    src: url('/assets/fonts/mantra-style-regular.ttf') format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Euro Style';
    src: url('/assets/fonts/euro-style-regular.ttf') format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Euro Style';
    src: url('/assets/fonts/euro-style-bold.ttf') format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Myriad Pro';
    src: url('/assets/fonts/myriad-pro-regular.ttf') format('truetype');
    font-weight: normal;
  }

  /*reset*/
  html, body, div, span, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  abbr, address, cite, code,
  del, dfn, em, img, ins, kbd, q, samp,
  small, strong, sub, sup, var,
  b, i,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section, summary,
  time, mark, audio, video, button {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }

  article, aside, details, figcaption, figure, dialog,
  footer, header, hgroup, menu, nav, section, main {
    display: block;
  }

  img, video {
    max-width: 100%;
    width: 100%;
    height: auto;
    display: block;
  }

  blockquote, q {
    quotes: none;

    &:before, &:after {
      content: none;
    }
  }

  a {
    text-decoration: none;
    font-size: 100%;
    color: inherit;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  /**
   * Paul Irish box sizing reset so all elements have broder-box.
   */
  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
    font-family: inherit;
  }

  /**
   * custom smarty resets
   */
  a {
    text-decoration: none;
  }

  button, input, a, textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    }
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  /*global*/
  body {
    font-family: ${({ theme }) => theme.font.secondary};
    font-weight: 400;
    letter-spacing: 0.08em;
    color: ${({ theme }) => theme.colors.secondary};
    background-image: url("/assets/bg-desktop.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    max-width: 100vw;
    overflow-x: hidden;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        background-image: url("/assets/bg-mobile.jpg");
    }
  }

  .main-wrapper {
  }

  //typography
  h1, h2 {
    font-family: ${({ theme }) => theme.font.primary};
    text-transform: uppercase;
  }

  h1, h2, h3, h4, h5, h6, span, p, a, button {
    letter-spacing: 0.08em;
    font-weight: 400;
  }

  .font {
    &-base {
      font-size: 1.519vw;
      line-height: 2.17vw;

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 3.798vw;
        line-height: 5.425vw;
      }
    }
  }

  .text {
    &-left {
      text-align: left;
    }

    &-center {
      text-align: center;
    }

    &-right {
      text-align: right;
    }
  }

  .text-shadow {
    filter: drop-shadow(0 0 0.2em ${({ theme }) => theme.colors.primary});
  }

  //typography

  // components
  .container {
    &, &-md, &-sm {
      padding: 0 40px;

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        padding: 0 16px;
      }

      &, &-inner {
        width: 100%;
        margin: 0 auto;
      }

      &-inner {
        padding: 0;
      }

      & {
        max-width: 1200px;

        @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
          max-width: 1152px;
        }

        &-inner {
          max-width: 1120px;
        }
      }

      &-md {
        max-width: 818px;

        @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
          max-width: 770px;
        }

        &-inner {
          max-width: 738px;
        }
      }

      &-sm {
        max-width: 540px;

        @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
          max-width: 492px;
        }

        &-inner {
          max-width: 460px;
        }
      }
    }
  }

  // components

  /*colors*/
 .text{
   &-primary {
      color: ${({ theme }) => theme.colors.primary};

     &-light {
        color: ${({ theme }) => theme.colors.primaryLight};
     }
   }

    &-secondary {
      color: ${({ theme }) => theme.colors.secondary};
    }

   &-light-green {
      color: ${({ theme }) => theme.colors.lightGreen};
   }
 }

  /*animations*/
  .animated {
    opacity: 0;
  }

  .fade-in {
    animation: ${fadeIn} .5s ease-in forwards;
  }

  body > iframe {
    pointer-events: none !important; /*for development*/
    max-height: 1px !important;
    max-width: 1px !important;
    position: absolute !important;
    z-index: -1000 !important;
    overflow: hidden !important;
  }

  .list-dash {
    margin-left: 20px;

    li {
      list-style-type: '- ';
      margin-bottom: 12px;

      p {
        padding-bottom: 0 !important;
      }
    }
  }

  .btn {
    &_primary {
      display: inline-flex;
      padding: 1.666vw 3.541vw;
      font-family: ${({ theme }) => theme.font.primary};
      font-size: 1.416vw;
      color: ${({ theme }) => theme.colors.secondary};
      background-image: linear-gradient(-168deg, rgba(92, 161, 173, 1) 0%, rgba(52, 86, 138, 1) 100%);
      justify-content: center;
      align-items: center;

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 3.541vw;
        padding: 3.906vw 8.333vw;
      }
    }
  }
`
