import React from 'react'
import {
  SectionTeamStyle,
  TeamContainer,
  TeamContent, TeamMembersContainer, TeamTitle
} from './SectionTeam.style'
import TeamMember from './TeamMember/TeamMember'
import { teamData } from './team-data'
import { forwardRef } from 'react'

const SectionTeam = forwardRef((props, ref) => {
  return (
    <SectionTeamStyle ref={ref} id="team">
      <TeamContainer>
        <TeamContent>
          <TeamTitle>
            <div />
            <h2><span>THE</span> <br/> TEAM</h2>
            <div />
          </TeamTitle>

          <TeamMembersContainer>
            <div className="row-1">
              {teamData.slice(0, 3).map((data, idx) => (
                <TeamMember {...data} key={`team-member-1-${idx}`} />
              ))}
            </div>

            <div className="row-2">
              {teamData.slice(3, 6).map((data, idx) => (
                <TeamMember {...data} key={`team-member-2-${idx}`} />
              ))}
            </div>

            <div className="row-3">
              {teamData.slice(6, 9).map((data, idx) => (
                <TeamMember {...data} key={`team-member-3-${idx}`} />
              ))}
            </div>
          </TeamMembersContainer>
        </TeamContent>
      </TeamContainer>
    </SectionTeamStyle>
  )
})

export default SectionTeam
