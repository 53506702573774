import styled from 'styled-components'

export const TeamMemberStyle = styled.div`
  text-align: center;
  font-family: ${({ theme }) => theme.font.secondary};
  display: flex;
  flex-direction: column;
  width: 100%;

  > h3 {
    font-size: 1.953vw;
    line-height: 1.832vw;
    font-weight: 600;
    margin-top: 1.041vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 4.882vw;
      line-height: 4.582vw;
      margin-top: 3vw;
    }
  }

  > span {
    font-size: 1.643vw;
    line-height: 1.542vw;
    color: ${({ theme }) => theme.colors.primary};
    display: inline-block;
    margin-top: 0.833vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 4.109vw;
      line-height: 3.855vw;
    }
  }

  > p {
    font-size: 1.302vw;
    line-height: 2.17vw;
    margin-top: 1.041vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 3.255vw;
      line-height: 5.425vw;
      margin-top: 2vw;
    }
  }
`
