import styled from 'styled-components'

export const SectIonPioneersStyle = styled.section`
  padding-top: 3.854vw;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 5.625vw;
    padding-top: 10vw;
  }
`

export const PioneersContainer = styled.div`
  max-width: 87.5vw;
  margin: 0 auto;
`

export const PioneersContent = styled.div`
  max-width: 81.25vw;
  margin: 0 auto;

  h2 {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 3.472vw;
    line-height: 3.689vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 6.665vw;
      line-height: 7.082vw;
      text-align: center;
    }

    > span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  p {
    margin-top: 2.5vw;
    letter-spacing: 0.1em;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        text-align: center;
    }
  }
`

export const PioneersCardsGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(1vw, 1fr));
  column-gap: 5.208vw;
  margin-top: 7vw;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 58vw;
    //grid-template-columns: 58vw;
    row-gap: 19.531vw;
    justify-content: center;
    margin-top: 19.27vw;
    display: none;
  }

  &.mobile {
    display: none;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      display: grid;
    }
  }

  .pioneer-card {
    &-1 {
      ::before {
        background-color: #050e15;
        opacity: 0.95;
      }

      > div {
        padding-top: 6.5vw;

        @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
          padding-top: 18vw;
        }

        > img {
          left: 0;
          top: -4vw;
          max-width: 10.416vw;

          @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
            max-width: 30vw;
            top: -12vw;
          }
        }
      }

    }

    &-2 {
      ::before {
        background-color: #161519;
        opacity: 0.97;
      }

      > div {
        padding-top: 6.5vw;

        @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
          padding-top: 20vw;
        }

        > img {
          left: 0;
          top: -6.5vw;
          max-width: 12.5vw;

          @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
            max-width: 37vw;
            top: -18.5vw;
          }
        }
      }
    }

    &-3 {
      ::before {
        background-color: #081e26;
        opacity: 0.97;
      }


      > div {
        padding-top: 8.125vw;

        @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
            padding-top: 25vw;
        }

        > img {
          max-width: 22.916vw;
          left: -2.083vw;
          top: -8.5vw;

          @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
            max-width: 100%;
            min-width: 70vw;
            left: -9vw;
           top: -25vw;
          }
        }

        > h3 {
          font-size: 2.507vw;
          line-height: 2.653vw;

          @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
            font-size: 6.269vw;
            line-height: 6.632vw;
          }
        }
      }

    }
  }
`
