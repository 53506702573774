import React, { forwardRef, useEffect, useRef } from 'react'
import {
  HeroBanner,
  HeroImageContainer, HeroTextContainer, HeroTextContent, HeroWrapper,
  SectionHeroStyle
} from './SectionHero.style'
import { Fade } from 'react-awesome-reveal'
import { baseSettings } from '../../utils/reveal'
import { gsap } from 'gsap'

const SectionHero = forwardRef(({ onLinkClick }, ref) => {
  const heroWrapperRef = useRef()
  const desktopImageRef = useRef()
  const mobileImageRef = useRef()
  const tlDesktop = useRef()
  const tlMobile = useRef()

  useEffect(() => {
    tlDesktop.current = gsap
      .timeline({
        scrollTrigger: {
          trigger: heroWrapperRef.current,
          start: '-15% top',
          end: '10% top',
          scrub: 1.5,
          // markers: true
        }
      }).fromTo(
        desktopImageRef.current,
        {
          y: '-130vh',
          scale: 0,
        },
        {
          y: '3.5vw',
          scale: 1
        }
      )

    tlMobile.current = gsap
      .timeline({
        scrollTrigger: {
          trigger: heroWrapperRef.current,
          start: '-15% top',
          end: '20% top',
          scrub: 1.5,
          // markers: true
        }
      }).fromTo(
        mobileImageRef.current,
        {
          y:'-100vw',
          scale: 0,
        },
        {
          y: '33vw',
          scale: 1
        }
      )

  }, [])

  return (
    <SectionHeroStyle ref={ref} id="hero">
      <HeroWrapper ref={heroWrapperRef}>
        <HeroTextContainer>
          <Fade {...baseSettings}>
            <HeroTextContent>
              <h1 className="text-shadow">WE ARE <br /> <span>PIONEERS</span>
              </h1>

              <p>
                Poulpo Labs is a French Web3 production studio on a mission to
                foster art back into NFTs by empowering the best artists in the
                world to create groundbreaking and original collections that
                will leave a print in the history of NFTs.
              </p>

              <button className="btn-discover"
                      onClick={() => onLinkClick('#our-collection')}>
                Discover our collections
              </button>
            </HeroTextContent>
          </Fade>

        </HeroTextContainer>

        <HeroImageContainer>
          <img
            src="/assets/hero/hero-cards.png"
            alt="Poulpo Labs"
            className="desktop"
            ref={desktopImageRef}
          />

          <img
            src="/assets/hero/hero-img-mobile.png"
            alt="Poulpo Labs"
            className="mobile"
            ref={mobileImageRef}
          />
        </HeroImageContainer>
      </HeroWrapper>

      <HeroBanner>
        <div>
          <h3 className="text-shadow">
            POWERING THE FUTURE OF ART
          </h3>
        </div>

        <img src="/assets/band.png" alt="Poulpo Labs" className="desktop" />

        <img src="/assets/band.png" alt="Poulpo Labs" className="mobile" />
      </HeroBanner>
    </SectionHeroStyle>
  )
})

export default SectionHero
