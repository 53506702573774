import styled, { css } from 'styled-components'

export const HeaderStyle = styled.header`
  position: fixed;
  z-index: 1000;
  width: 100%;


  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    transition: 0.5s;
    overflow: hidden;
    max-height: ${({ isMenuOpen }) => isMenuOpen ? '78vw' : '13.5vw'};
    height: 100%;
    max-width: 53.125vw;
    right: 0;
  }
`

export const HeaderContainer = styled.div`
  padding-left: 3.75vw;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {

    padding-left: 0;
    margin-left: auto;
    position: relative;
  }
`

export const HeaderContent = styled.div`
  display: flex;
  padding-top: 2.083vw;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 100%;
    min-height: 13.4vw;
    justify-content: space-between;
    padding: 2.994vw 2.864vw 3.515vw;
    transition: 0.5s;
    background-image: ${({ isMenuOpen }) => (
      isMenuOpen
        ? 'linear-gradient(90deg, rgba(22,50,60,1) 0%, rgba(5,19,26,1) 100%)'
        : 'none')};
  }
`

export const HeaderLogo = styled.div`
  max-width: 5vw;
  cursor: pointer;


  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 7.552vw;
  }
`

export const HeaderNav = styled.nav`
  display: flex;
  align-items: center;
  margin-left: 12.187vw;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    transition: 0.5s;
    position: absolute;
    background-color: #020d14;
    top: 13.4vw;
    width: 100%;
    margin-left: 0;
    padding: 3.125vw 6.25vw;
    left: 0;
    opacity: ${({ isMenuOpen }) => (isMenuOpen ? '1' : '0')};
  }

  > ul {
    display: flex;
    flex-direction: row;
    font-size: 1.302vw;
    line-height: 2.17vw;
    list-style-type: none;
    font-family: ${({ theme }) => theme.font.secondary};
    letter-spacing: 0.08em;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 3.776vw;
      flex-direction: column;
      width: 100%;
    }

    > li {
      cursor: pointer;

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-top: 4.687vw;
        padding-bottom: 4.687vw;
        width: 100%;

        :not(:last-child) {
          margin-right: 0;
          border-bottom: 1px solid #686e73;
        }

      }

      :not(:last-child) {
        margin-right: 5.25vw;
      }
    }
  }
`

export const HeaderHamburgerBtn = styled.button`
  display: none;
  border: none;
  cursor: pointer;
  background: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;

      ${({ isMenuOpen }) => isMenuOpen && css`
      height: 6.9vw;
      width: 6.9vw;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #02070a;
    `}

      > img {
        width: ${({ isMenuOpen }) => isMenuOpen ? '4vw' : '7vw'};
      }
  }



`
