import styled from 'styled-components'

export const SectionPropellersStyle = styled.section`
  padding-top: 7.208vw;
  margin-top: 3vw;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 5.104vw;
    padding-top: 10vw;
  }
`

export const PropellersWrapper = styled.div`
  background-image: url("/assets/propellers/bg-right.png");
  background-repeat: no-repeat;
  background-position: right 2%;
  background-size: 19.27vw;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    background-image: none;
  }
`

export const PropellersContainer = styled.div`
  max-width: 81.25vw;
  margin: 0 auto;
`

export const PropellersContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PropellersTitleContainer = styled.div`
  max-width: 54.166vw;
  margin: 0 auto;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 78vw;
  }

  > h2 {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 3.472vw;
    line-height: 3.689vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 6.665vw;
      line-height: 7.082vw;
    }

    > span {
      color: ${({ theme }) => theme.colors.primary}
    }
  }

  > p {
    margin-top: 3.333vw;
    font-size: 1.519vw;
    line-height: 2.17vw;
    letter-spacing: 0.08em;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 3.798vw;
      line-height: 5.425vw;
      margin-top: 5.208vw;
    }
  }
`

export const PropellersGroup = styled.div`
  margin-top: 5.208vw;
  background-image: url("/assets/propellers/bg-center.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 82%;
  display: block;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    background-image: none;
    display: none;
  }

  &.mobile {
    display: none;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        display: block;
    }
  }

  .row {
    &-1, &-2 {
      display: grid;
    }

    &-1 {
      grid-template-columns: repeat(3, 20vw);
      align-items: end;
      column-gap: 8.333vw;

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        grid-template-columns: 46.875vw;
        row-gap: 10.416vw;
      }


      &-item {
        &-2 {
          margin-bottom: 3vw;

          @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
            margin-bottom: 0;
          }
        }
      }
    }

    &-2 {
      grid-template-columns: repeat(2, 20vw);
      margin-top: 4.166vw;
      column-gap: 10.416vw;
      justify-content: center;

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        grid-template-columns: 46.875vw;
        row-gap: 8vw;
        margin-top: 8vw;
      }

      .propellers-item {
        &:nth-child(1) {
          justify-self: end;
        }
      }
    }
  }
`

export const PropellersBtn = styled.a`
  margin-top: 6.458vw;
  display: inline-flex;
  padding: 1.666vw 3.541vw;
  font-family: ${({ theme }) => theme.font.primary};
  font-size: 1.416vw;
  color: ${({ theme }) => theme.colors.secondary};
  background-image: linear-gradient(-168deg, rgba(92, 161, 173, 1) 0%, rgba(52, 86, 138, 1) 100%);
  justify-content: center;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 3.541vw;
    padding: 3.906vw 8.333vw;
  }
`

export const PropellersBanner = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  width: 100%;
  position: relative;
  z-index: 1;
  margin-top: 2.083vw;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 20.312vw;
  }

  > div {
    margin: 0 auto;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 32%;

    > a {
      display: block;
    }

    img {
      width: auto;
      max-height: 5.625vw;

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        max-height: 11.718vw;
      }
    }
  }

  > img {
    position: relative;
    z-index: 1;
    opacity: .45;
  }

  .desktop {
    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      display: none;
    }
  }

  .mobile {
    display: none;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      display: block;
    }
  }
`
