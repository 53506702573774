export const headerData = [
  {
    name: 'Discover Poulpo',
    to: '#discover'
  },
  {
    name: 'Our promise',
    to: '#propellers'
  },
  {
    name: 'Our services',
    to: "#pioneers"
  },
  {
    name: "Collections",
    to: "#our-collection"
  },
  {
    name: "Team",
    to: "#team"
  }
]
