import React, { useEffect, useRef, useState } from 'react'
import SectionTeam from './components/SectionTeam/SectionTeam'
import Footer from './components/Footer/Footer'
import SectionOurCollection
  from './components/SectionOurCollection/SectionOurCollection'
import SectionPropellers from './components/SectionPropellers/SectionPropellers'
import SectionDiscover from './components/SectionDiscover/SectionDiscover'
import SectionHero from './components/SectionHero/SectionHero'
import { Header } from './components/Header/Header'
import { SectIonPioneers } from './components/SectIonPioneers/SectIonPioneers'
import SectionWork from './components/SectionWork/SectionWork'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { gsap } from 'gsap'

const scrollIntoView = require('scroll-into-view')

gsap.registerPlugin(ScrollTrigger)

const App = () => {
  const [scrollTo, setScrollTo] = useState(null)
  const heroRef = useRef(null)
  const discoverRef = useRef(null)
  const propellersRef = useRef(null)
  const pioneersRef = useRef(null)
  const ourCollectionRef = useRef(null)
  const teamRef = useRef(null)

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, [])

  const refMapping = {
    '#discover': discoverRef,
    '#propellers': propellersRef,
    '#pioneers': pioneersRef,
    '#our-collection': ourCollectionRef,
    '#team': teamRef,
    '#hero': heroRef
  }

  useEffect(() => {
    if (scrollTo) {
      scrollIntoView(refMapping[scrollTo]?.current, {
        align: {
          top: 0
        }
      })
      setScrollTo(false)
    }
  }, [scrollTo])

  const handleLinkClick = to => setScrollTo(to)

  return (
    <>
      <Header onLinkClick={handleLinkClick} />

      <SectionHero ref={heroRef} onLinkClick={handleLinkClick}/>

      <SectionWork />

      <SectionDiscover ref={discoverRef}/>

      <SectionPropellers ref={propellersRef}/>

      <SectIonPioneers ref={pioneersRef}/>

      <SectionOurCollection ref={ourCollectionRef}/>

      <SectionTeam ref={teamRef}/>

      <Footer />
    </>
  )
}

export default App
