import React from 'react'
import {
  DiscoverTitle, SectionMichael, SectionPascal,
  SectionWorkContent,
  SectionWorkStyle,
  SectionWorkTitle
} from './SectionWork.style'
import { Container, ContainerMd, Line } from '../../styles/components.style'
import WorkArtistCard from './WorkArtistCard/WorkArtistCard'
import { carouselData, workArtistCardsData } from './work-data'
import WorkCollectionName from './WorkCollectionName/WorkCollectionName'
import { Carousel } from './Carousel/Carousel'

const SectionWork = () => {
  return (
    <SectionWorkStyle>
      <Container>
        <SectionWorkTitle>
          <h2 className="text-secondary text-shadow">
            WE WORK <br />
            <span className="small"> WITH</span>
            <span className="text-primary-light"> LEGENDS</span>
          </h2>
          <Line />
        </SectionWorkTitle>
      </Container>


      <SectionWorkContent>
        <SectionMichael>
          <WorkArtistCard
            {...workArtistCardsData[0]}
          />

          <ContainerMd>
            <DiscoverTitle className="text-shadow">
              DISCOVER HIS WORLD
            </DiscoverTitle>

            <div className="discover-collections">
              <WorkCollectionName
                company="Entertainment industry"
                collection="NFT"
              />

              <WorkCollectionName
                company="Disney"
                collection="SuperRare"
              />
            </div>

            <div className="collections">
              <div className="collections-1">
                <div className="img-wrapper">
                  <img src="/assets/work/super-rare.png" alt="SuperRare"
                       className="super-rare" />
                </div>

                <Carousel
                  imagesList={carouselData[0]}
                />
              </div>

              <div className="collections-2">
                <div className="img-wrapper">
                  <img src="/assets/work/disney.png" alt="Disney"
                       className="disney" />
                </div>

                <Carousel
                  imagesList={carouselData[1]}
                />
              </div>
            </div>
          </ContainerMd>
        </SectionMichael>

        <SectionPascal>
          <WorkArtistCard
            {...workArtistCardsData[1]}
          />

          <ContainerMd>
            <DiscoverTitle className="text-shadow">
              DISCOVER HIS WORLD
            </DiscoverTitle>

            <div className="discover-collections">
              <WorkCollectionName
                company="Video game industry"
                collection="Personal art"
              />
            </div>

            <div className="collections">
              <div className="collections-1">
                <Carousel
                  imagesList={carouselData[2]}
                />
              </div>
            </div>
          </ContainerMd>
        </SectionPascal>
      </SectionWorkContent>
    </SectionWorkStyle>
  )
}

export default SectionWork
