import styled from 'styled-components'

export const WorkCollectionNameStyle = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.font.tertiary};
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1.66vw;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 4.15vw;
  }

  .company, .collection {
    flex-shrink: 0;
  }

  .company {
    font-weight: 100;
  }

  .collection {
    color: ${({ theme }) => theme.colors.primaryLight};
    font-family: ${({ theme }) => theme.font.secondary};
  }

  .dots {
    font-size: 0.83vw;
    white-space: nowrap;
    overflow: hidden;
    align-self: flex-end;
    margin-bottom: 0.28vw;
    margin-left: 1vw;
    margin-right: 1vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 2vw;
      margin-left: 2vw;
      margin-right: 2vw;
      margin-bottom: 0.75vw;
    }
  }
`
