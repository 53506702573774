import React, { useRef, useEffect, useState } from 'react'
import {
  PropellersBanner,
  PropellersBtn,
  PropellersContainer,
  PropellersContent,
  PropellersGroup,
  PropellersTitleContainer, PropellersWrapper,
  SectionPropellersStyle
} from './SectionPropellers.style'
import PropellersItem from './PropellersItem/PropellersItem'
import { propellersData } from './propellers-data'
import { forwardRef } from 'react'
import { Fade } from 'react-awesome-reveal'
import { baseSettings } from '../../utils/reveal'
import { gsap } from 'gsap'

const SectionPropellers = forwardRef((props, ref) => {
  const groupRef = useRef()
  const groupSelector = gsap.utils.selector(groupRef)
  const [allScrollTriggers, setAllScrollTriggers] = useState([])

  const setAnimations = () => {
    const itemsArray = gsap.utils.toArray(groupSelector('.propellers-item'))

    itemsArray.forEach(item => {
      const trigger = gsap.timeline({
        scrollTrigger: {
          trigger: item,
          start: '-20% center',
          end: 'bottom center',
          // markers: true,
          scrub: 0,

        }
      }).from(item, {
        opacity: 0
      })
        .to(item, {
          opacity: 1
        }).to(item, {
          opacity: 0
        })

      setAllScrollTriggers(allScrollTriggers => [...allScrollTriggers, trigger])
    })
  }

  useEffect(() => {
    setAnimations()
  }, [])

  return (
    <SectionPropellersStyle ref={ref} id="propellers">
      <PropellersWrapper>
        <PropellersContainer>
          <PropellersContent>
            <Fade {...baseSettings}>
              <PropellersTitleContainer>
                <h2 className="text-shadow">WE ARE <br />
                  <span>PROPELLERS</span>
                </h2>

                <p>
                  At Poulpo we are dedicated to providing artists with the best
                  possible conditions to
                  create portals to beautiful worlds and universes in which
                  people
                  may find
                  themselves, or get lost.
                </p>
              </PropellersTitleContainer>
            </Fade>

            <PropellersGroup>
              <div className="row-1">
                <Fade {...baseSettings}>
                  {propellersData.slice(0, 3).map((data, idx) => (
                    <PropellersItem
                      {...data}
                      kay={`propeller-1-${idx}`}
                      className={`row-1-item-${idx+1}`}
                    />
                  ))}
                </Fade>
              </div>

              <div className="row-2">
                <Fade {...baseSettings}>
                  {propellersData.slice(3).map((data, idx) => (
                    <PropellersItem
                      {...data}
                      kay={`propeller-2-${idx}`}
                      className={`row-2-item-${idx+1}`}
                    />
                  ))}
                </Fade>
              </div>
            </PropellersGroup>

            <PropellersGroup ref={groupRef} className="mobile">
              <div className="row-1">
                  {propellersData.slice(0, 3).map((data, idx) => (
                    <PropellersItem
                      {...data}
                      kay={`m-propeller-1-${idx}`}
                      className={`row-1-item-${idx+1}`}
                    />
                  ))}
              </div>

              <div className="row-2">
                  {propellersData.slice(3).map((data, idx) => (
                    <PropellersItem
                      {...data}
                      kay={`m-propeller-2-${idx}`}
                      className={`row-2-item-${idx+1}`}
                    />
                  ))}
              </div>
            </PropellersGroup>

            <Fade {...baseSettings}>
              <PropellersBtn
                href="https://twitter.com/PoulpoLabs"
                target="_blank"
                rel="noreferrer"
              >
                CONNECT WITH US
              </PropellersBtn>
            </Fade>
          </PropellersContent>
        </PropellersContainer>

        <PropellersBanner>

          <div>
            <Fade {...baseSettings}>
              <a
                href="https://twitter.com/Blade7NFT"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/collections/b7.png" alt="Blade7" />
              </a>

              <a
                href="https://twitter.com/PhantomZeroNFT"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/propellers/ghost.png" alt="Phantom" />
              </a>
            </Fade>
          </div>

          <img src="/assets/band.png" alt="Poulpo Labs" className="desktop" />

          <img src="/assets/shape-sm.png" alt="Poulpo Labs"
               className="mobile" />
        </PropellersBanner>
      </PropellersWrapper>
    </SectionPropellersStyle>
  )
})

export default SectionPropellers
