import styled from 'styled-components'

export const OurCollectionCardStyle = styled.div`
  > .mobile-title {
    display: none;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    > h3 {
      font-size: 4.95vw;
      font-family: ${({ theme }) => theme.font.primary};
      color: ${({ theme }) => theme.colors.secondary};
      line-height: 5.2vw;
      width: 100%;
      //background-color: red;
      text-align: center;

      > span {
        color: ${({ theme }) => theme.colors.lightGreen};
      }
    }

    > .triangle {
      //background-color: red;

      &-right, &-left {
        position: relative;

        ::after {
          content: '';
          position: absolute;
          inset: 0;
          z-index: 3;
        }
      }

      &-left {
        ::after {
          background-image: linear-gradient(90deg, rgba(22, 50, 60, 0) 80%, rgba(0, 0, 0, .5) 100%);
        }
      }

      &-right {
        ::after {
          background-image: linear-gradient(270deg, rgba(22, 50, 60, 0) 80%, rgba(0, 0, 0, .5) 100%);
        }
      }
    }
  }
`

export const Content = styled.div`
  max-width: 81.25vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column-reverse;
    margin-top: 9.5vw;
  }


  &.reverse {
    flex-direction: row-reverse;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      flex-direction: column-reverse;
    }
  }
`

export const OurCollectionCardDescription = styled.div`
  align-self: end;
  width: calc(50% - 0.625vw);

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 100%;
    display: grid;
    margin-top: 7.5vw;
  }

  > h3 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 3.472vw;
    line-height: 3.689vw;
    font-family: ${({ theme }) => theme.font.primary};

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 6.665vw;
      line-height: 7.082vw;
      width: 100%;
      display: none;
    }

    > span {
      color: ${({ theme }) => theme.colors.primary}
    }
  }

  > h4 {
    font-family: ${({ theme }) => theme.font.primary};
    font-size: 1.87vw;
    line-height: 2.17vw;
    color: ${({ theme }) => theme.colors.secondary};
    margin-top: 2.55vw;
    text-align: center;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 4.4vw;
      line-height: 5.425vw;
      color: ${({ theme }) => theme.colors.primary};
    }

    > span {
      color: ${({ theme }) => theme.colors.primary};

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        display: block;
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }

  > p {
    margin-top: 3.05vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      text-align: center;
      margin-top: 8vw;
    }
  }

  > a {
    margin-top: 4.05vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      justify-self: center;
      margin-top: 8.8vw;
    }
  }
`

export const OurCollectionCardImages = styled.div`
  position: relative;
  grid-area: card-images;
  width: calc(50% - 0.625vw);

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 100%;
    display: grid;
    justify-content: center;
  }

  > .artist {
    position: absolute;
    top: 0;
    left: 0;
    width: 11.66vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 22vw;
      left: -5vw;
    }
  }

  > .collection {
    border-radius: 8%;
    width: calc(100% - 6vw);
    margin-left: auto;
    margin-top: auto;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 70vw;
    }
  }

  &.reverse {
    .artist {
      left: -5.88vw;

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        left: -5vw;
      }
    }

    .collection {
      margin-left: 0;
      margin-right: auto;
    }
  }
`
