import React from 'react'
import {
  Content,
  OurCollectionCardDescription, OurCollectionCardImages,
  OurCollectionCardStyle
} from './OurCollectionCard.style'
import { Triangle } from '../../../styles/components.style'
import { theme } from '../../../styles/theme'

export const OurCollectionCard = ({
                                    collectionName,
                                    artist,
                                    description,
                                    link,
                                    collectionImage,
                                    artistImage,
                                    reverse,
                                    className = ''
                                  }) => {
  return (
    <OurCollectionCardStyle
      className={[
        'our-collection-card',
        className
      ].join(' ')}
    >
      <div className="mobile-title">
        <div className="triangle-left">
          <Triangle left width={24} height={.6}
                    color={theme.colors.lightGreen} />
        </div>

        <h3 className="text-shadow ">
          {collectionName}
        </h3>

        <div className="triangle-right">
          <Triangle right width={24} height={.6}
                    color={theme.colors.lightGreen} />
        </div>
      </div>

      <Content className={reverse ? 'reverse' : ''}>
        <OurCollectionCardDescription>
          <h3 className="text-shadow">
            {collectionName}
          </h3>

          <h4 className="text-shadow">
            Artist:
            <span>{artist}</span>
          </h4>

          <p className="font-base">
            {description}
          </p>

          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="btn_primary"
          >DISCOVER MORE</a>
        </OurCollectionCardDescription>

        <OurCollectionCardImages
          className={[
            reverse ? 'reverse' : ''
          ].join(' ')}
        >
          <img
            src={collectionImage}
            alt={collectionName}
            className="collection"
          />

          <img
            src={artistImage}
            alt={artist}
            className="artist"
          />
        </OurCollectionCardImages>
      </Content>
    </OurCollectionCardStyle>
  )
}

