export const workArtistCardsData = [
  {
    name: 'MICHAEL KUTSCHE',
    description:
      <>
        Award-winning and world-renowned artist, painter, character
        designer. Michael’s unique approach of character design and
        creation has led him to become the artist behind some of Disney’s
        most famous movies and a successful NFT artist with curated
        pieces trading for no less than 100 ETH.
      </>,
    image: '/assets/work/artist-1.png'
  },

  {
    name: 'PASCAL BLANCHÉ',
    description:
      <>
        Digital art legend, senior art director, head in the stars since
        1977. Pascal’s unique vision of art made him one of the most
        respected digital artists on the planet, among the greatest.
        Behind video games such as Far Cry: New Dawn, Avatar and
        Runescape, Pascal is mostly known for his world-famous personal
        artworks.
      </>,
    image: '/assets/work/artist-2.png'
  }
]

export const carouselData = [
  [
    {
      src: '/assets/work/carousel-1/carousel-1-1.jpg',
      alt: 'Super Rare'
    },

    {
      src: '/assets/work/carousel-1/carousel-1-2.jpg',
      alt: 'Super Rare'
    },

    {
      src: '/assets/work/carousel-1/carousel-1-3.jpg',
      alt: 'Super Rare'
    },

    {
      src: '/assets/work/carousel-1/carousel-1-4.jpg',
      alt: 'Super Rare'
    },

    {
      src: '/assets/work/carousel-1/carousel-1-5.jpg',
      alt: 'Super Rare'
    },
  ],

  [
    {
      src: '/assets/work/carousel-2/carousel-2-1.jpg',
      alt: 'Disney'
    },

    {
      src: '/assets/work/carousel-2/carousel-2-2.jpg',
      alt: 'Disney'
    },

    {
      src: '/assets/work/carousel-2/carousel-2-3.jpg',
      alt: 'Disney'
    },

    {
      src: '/assets/work/carousel-2/carousel-2-4.jpg',
      alt: 'Disney'
    },

    {
      src: '/assets/work/carousel-2/carousel-2-5.jpg',
      alt: 'Disney'
    },

    {
      src: '/assets/work/carousel-2/carousel-2-6.jpg',
      alt: 'Disney'
    },

    {
      src: '/assets/work/carousel-2/carousel-2-7.jpg',
      alt: 'Disney'
    },
  ],

  [
    {
      src: '/assets/work/carousel-3/carousel-3-1.jpg',
      alt: 'Pascal Blanché'
    },

    {
      src: '/assets/work/carousel-3/carousel-3-2.jpg',
      alt: 'Pascal Blanché'
    },

    {
      src: '/assets/work/carousel-3/carousel-3-3.jpg',
      alt: 'Pascal Blanché'
    },

    {
      src: '/assets/work/carousel-3/carousel-3-4.jpg',
      alt: 'Pascal Blanché'
    },

    {
      src: '/assets/work/carousel-3/carousel-3-5.jpg',
      alt: 'Pascal Blanché'
    },
  ]
]
