import React from 'react'
import {
  DiscoverContainer, DiscoverContent,
  SectionDiscoverStyle
} from './SectionDiscover.style'
import { forwardRef } from 'react'

const SectionDiscover = forwardRef((props, ref) => {
  return (
    <SectionDiscoverStyle id="discover" ref={ref}>
      <DiscoverContainer>
        <DiscoverContent>
          <h2 className="text-shadow">DISCOVER <span>POULPO</span></h2>

          <iframe
            src="https://www.youtube.com/embed/wtfBDzCS_2g"
            title="Poulpo labs" frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </DiscoverContent>
      </DiscoverContainer>
    </SectionDiscoverStyle>
  )
})

export default SectionDiscover
