import React, { useEffect, useState } from 'react'
import {
  HeaderContainer,
  HeaderContent, HeaderHamburgerBtn,
  HeaderLogo, HeaderNav,
  HeaderStyle
} from './Header.style'
import { headerData } from './header-data'
import { useLockedBody } from '../../hooks/useLockedBody'

export const Header = ({onLinkClick}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [locked, setLocked] = useLockedBody();

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
    setLocked(!locked);
  };

  const handleMenuLinkClick = (to) => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
      setLocked(false);
    }
    onLinkClick(to);
  };

  const handleResize = () => {
    const windowWidth = window.innerWidth

    if (windowWidth > 1199) {
      setLocked(false)
      setIsMenuOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <HeaderStyle isMenuOpen={isMenuOpen}>
      <HeaderContainer>
        <HeaderContent  isMenuOpen={isMenuOpen}>
          <HeaderLogo onClick={() => onLinkClick('#hero')}>
            <img src="/assets/logo.png" alt="Poulpo Labs" />
          </HeaderLogo>

            <HeaderNav isMenuOpen={isMenuOpen}>
              <ul>
                {headerData.map(({ name, to }, idx) => (
                  <li
                    key={`header-link-${idx}`}
                    onClick={() => handleMenuLinkClick(to)}
                  >
                    {name}
                  </li>
                ))}
              </ul>
            </HeaderNav>

          <HeaderHamburgerBtn
            onClick={handleMenuToggle}
            isMenuOpen={isMenuOpen}
          >
            <img
              src={`/assets/header/${isMenuOpen ? 'close' : 'menu'}.png`}
              alt="menu"
            />
          </HeaderHamburgerBtn>
        </HeaderContent>
      </HeaderContainer>
    </HeaderStyle>
  )
}

