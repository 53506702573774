import React,{ useEffect, useRef, useState } from 'react'
import {
  PioneersCardsGroup,
  PioneersContainer, PioneersContent,
  SectIonPioneersStyle
} from './SectIonPioneers.style'
import { PioneerCard } from './PioneerCard/PioneerCard'
import { pioneersData } from './pioneers-data'
import { forwardRef } from 'react'
import { baseSettings } from '../../utils/reveal'
import { Fade } from 'react-awesome-reveal'
import { gsap } from 'gsap'

export const SectIonPioneers = forwardRef((props, ref) => {
  const groupRef = useRef()
  const groupSelector = gsap.utils.selector(groupRef)
  const [allScrollTriggers, setAllScrollTriggers] = useState([])

  const setAnimations = () => {
    const itemsArray = gsap.utils.toArray(groupSelector('.pioneer-card'))

    itemsArray.forEach(item => {
      const trigger = gsap.timeline({
        scrollTrigger: {
          trigger: item,
          start: '-30% center',
          end: 'bottom center',
          // markers: true,
          scrub: 0,

        }
      }).from(item, {
        opacity: 0
      })
        .to(item, {
          opacity: 1
        }).to(item, {
          opacity: 0
        })

      setAllScrollTriggers(allScrollTriggers => [...allScrollTriggers, trigger])
    })
  }

  useEffect(() => {
    setAnimations()
  }, [])

  return (
    <SectIonPioneersStyle ref={ref} id="pioneers">
      <PioneersContainer>
        <PioneersContent>
          <Fade {...baseSettings}>
            <h2 className="text-shadow">WE ARE <br /><span>EXPERTS</span></h2>

            <p className="font-base">
              We leverage our wide network and cutting-edge expertise to make
              sure the collections we work on are successful. This means artists
              can focus on their art while we take care of the rest.
            </p>
          </Fade>

          <PioneersCardsGroup>
            <Fade cascade {...baseSettings} fraction={0.1} damping={0.1}>
              {pioneersData.map((data, idx) => (
                <PioneerCard
                  key={`pioneer_${idx}`}
                  className={`pioneer-card-${idx + 1}`}
                  {...data}
                />
              ))}
            </Fade>
          </PioneersCardsGroup>

          <PioneersCardsGroup className="mobile" ref={groupRef}>
              {pioneersData.map((data, idx) => (
                <PioneerCard
                  key={`m_pioneer_${idx}`}
                  className={`pioneer-card-${idx + 1}`}
                  {...data}
                />
              ))}
          </PioneersCardsGroup>
        </PioneersContent>
      </PioneersContainer>
    </SectIonPioneersStyle>
  )
})
