import React from 'react'
import { FooterContainer, FooterContent, FooterStyle } from './Footer.style'

const Footer = () => {
  return (
    <FooterStyle>
      <FooterContainer>
        <FooterContent>
          <h2 className="text-shadow">FOLLOW <br/> THE <span>ADVENTURE</span></h2>

          <a href="https://twitter.com/PoulpoLabs" target="_blank" rel="noreferrer">
            <img src="/assets/footer/twitter.png" alt="twitter-link" />
          </a>
        </FooterContent>
      </FooterContainer>
    </FooterStyle>
  )
}

export default Footer
